
export const NAV_BAR = [
  {
    id: 1,
    label: 'expertise',
    url: '#expertise'
  },
  {
    id: 2,
    label: 'lifecycle',
    url: '#lifecycle'
  },
  {
    id: 3,
    label: 'who we are',
    url: '#who-we-are'
  },
  {
    id: 4,
    label: 'services',
    url: '#services'
  },
  {
    id: 5,
    label: 'tech stack',
    url: '#tech-stack'
  },
]